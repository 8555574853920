.App {
  background-color: #00669925;
  color: #0a1f34;
}

.App-Content {
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-bottom: 10vh;
  font-family: 'Trebuchet MS', sans-serif;
}

.Step {
  /* border: 1px #00669920 solid; */
  /* background-color: white; */

  background-color: #ffffff70;

  /* background-color: #00669910; */
  
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 25px;
  width: 80vw;
  max-width: 800px;
  margin: auto;
  margin-bottom: 25px;
  

}

.step4-attest{
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.button {
  font-size: 16px;
  padding: 5px;
}

.Download-Content {
  text-decoration: none;
  color: black;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 16px;
  padding: 2px;
}

.Download-Button {
  text-decoration: none;
  color: black;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 16px;
  padding: 5px;
}

.step5-note{
  padding-left: 10px;
  padding-right: 10px;
}

.Footer {
  text-align: center;
  background-color: #00669925;
  padding-bottom: 4vh;
  padding-left: 5vw;
  padding-right: 5vw;
  height: auto;
  font-family: 'Trebuchet MS', sans-serif;
}

/* unvisited link */
.link {
  color: blue;
}
.link:visited { color: grey; } 

